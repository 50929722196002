import React from "react"
import Helmet from 'react-helmet'
import Fight from '../components/Fight'
import queryString from 'query-string'



export default function Fighter ({pageContext}) {

const fight = pageContext

const parsed = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {}

  return (
    <>
      <Helmet>
      <title>{fight.title} - Vote for the Fights You Want to See.</title>
      <meta name="description" content={"Do you want to see " + fight.title + "? We're on a mission to put the power back in the hands of the fans."} />
      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
      </Helmet>
      <Fight fight={fight} login={parsed.login} />
    </>
  )
}